import L from "leaflet";
import "leaflet-routing-machine";
import {createControlComponent} from "@react-leaflet/core";
import React from "react";
import ReactDOMServer from 'react-dom/server';


const createRoutineMachineLayer = (props) => {
    let startPoint = L.latLng(props.latStart, props.longStart);
    let endPoint = L.latLng(props.latEnd, props.longEnd);

    let startIcon = L.divIcon({
        className: 'start-icon',
        html: '<svg xmlns="http://www.w3.org/2000/svg" height="2em" viewBox="0 0 512 512" class="car-color"><style>.car-color{fill:' + props.color + '}</style><path d="M135.2 117.4L109.1 192H402.9l-26.1-74.6C372.3 104.6 360.2 96 346.6 96H165.4c-13.6 0-25.7 8.6-30.2 21.4zM39.6 196.8L74.8 96.3C88.3 57.8 124.6 32 165.4 32H346.6c40.8 0 77.1 25.8 90.6 64.3l35.2 100.5c23.2 9.6 39.6 32.5 39.6 59.2V400v48c0 17.7-14.3 32-32 32H448c-17.7 0-32-14.3-32-32V400H96v48c0 17.7-14.3 32-32 32H32c-17.7 0-32-14.3-32-32V400 256c0-26.7 16.4-49.6 39.6-59.2zM128 288a32 32 0 1 0 -64 0 32 32 0 1 0 64 0zm288 32a32 32 0 1 0 0-64 32 32 0 1 0 0 64z"/></svg>',
        iconSize: [100, 100], // Kích thước biểu tượng (độ rộng, chiều cao)
        iconAnchor: [5, 10], // Vị trí mà biểu tượng sẽ được đặt (độ rộng, chiều cao)
        popupAnchor: [0, -32] // Vị trí hiển thị pop-up liên quan đến biểu tượng (độ rộng, chiều cao)
    });

    let dataPopup = '';
    if (props.markerPopupType === 0) {
        let qd = props.markerPopup?.DistanceXeKho
            ? `${props.markerPopup.DistanceXeKho.toFixed(1)}Km`
            : '';
        dataPopup = (
            <ul className='list-unstyled'>
                <li className='h6'>Thông tin</li>
                <li className='ms-2'>Biển số xe: {props.markerPopup.VehicleNumber_Keo}</li>
                <li className='ms-2'>Tên tài xế: {props.markerPopup['BL_Tracking_TXKeo::zcFullName']}</li>
                <li className='ms-2'>SĐT: {props.markerPopup['BL_Tracking_TXKeo::Phone']}</li>
                <li className='ms-2'>Quãng đường: {qd}</li>
                <li className='ms-2'>Thời gian dự kiến: {props.markerPopup.TimeXeKhoText}</li>
            </ul>
        );
    } else {
        let qd = props.markerPopup?.DistanceXeCang
            ? `${props.markerPopup.DistanceXeCang.toFixed(1)}Km`
            : '';
        dataPopup = (
            <ul className='list-unstyled'>
                <li className='h6'>Thông tin</li>
                <li className='ms-2'>Biển số xe: {props.markerPopup.VehicleNumber_Ha}</li>
                <li className='ms-2'>Tên tài xế: {props.markerPopup['BL_Tracking_TXHa::zcFullName']}</li>
                <li className='ms-2'>SĐT: {props.markerPopup['BL_Tracking_TXHa::Phone']}</li>
                <li className='ms-2'>Quãng đường: {qd}</li>
                <li className='ms-2'>Thời gian dự kiến: {props.markerPopup.TimeXeCangText}</li>
            </ul>
        );
    }
    return L.Routing.control({
        waypoints: [startPoint, endPoint],
        lineOptions: {
            styles: [{color: "#6FA1EC", weight: 4}]
        },
        show: false,
        addWaypoints: false,
        routeWhileDragging: false,
        draggableWaypoints: false,
        fitSelectedRoutes: false,
        showAlternatives: false,
        createMarker: (i, waypoint, n) => {
            // Sử dụng biểu tượng tùy chỉnh cho điểm đầu
            if (i === 0) {
                const popupContent = ReactDOMServer.renderToString(dataPopup);
                return L.marker(waypoint.latLng, {icon: startIcon}).bindPopup(popupContent);
            }
            // Sử dụng biểu tượng mặc định cho các điểm khác
            return L.marker(waypoint.latLng).bindPopup('Waypoint ' + i);
        },
    });
};

const RoutingMachine = createControlComponent(createRoutineMachineLayer);

export default RoutingMachine;
