import React, { useEffect, useState } from 'react'
import 'photoswipe/dist/photoswipe.css'
import { Gallery, Item } from 'react-photoswipe-gallery'
import { GalleryProps } from "react-photoswipe-gallery";

const GalleryImages = ({ images }) => {

  const uiElements: GalleryProps['uiElements'] = [
    {
      name: 'custom-rotate-button',
      ariaLabel: 'Rotate',
      order: 9,
      isButton: true,
      html: {
        isCustomSVG: true,
        inner:
          '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" width="18" height="18">' +
          '<path d="M125.7 160H176c17.7 0 32 14.3 32 32s-14.3 32-32 32H48c-17.7 0-32-14.3-32-32V64c0-17.7 14.3-32 32-32s32 14.3 32 32v51.2L97.6 97.6c87.5-87.5 229.3-87.5 316.8 0s87.5 229.3 0 316.8s-229.3 87.5-316.8 0c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0c62.5 62.5 163.8 62.5 226.3 0s62.5-163.8 0-226.3s-163.8-62.5-226.3 0L125.7 160z" fill="#111111" stroke="#111111" stroke-width="40"/>' +
          '<path d="M125.7 160H176c17.7 0 32 14.3 32 32s-14.3 32-32 32H48c-17.7 0-32-14.3-32-32V64c0-17.7 14.3-32 32-32s32 14.3 32 32v51.2L97.6 97.6c87.5-87.5 229.3-87.5 316.8 0s87.5 229.3 0 316.8s-229.3 87.5-316.8 0c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0c62.5 62.5 163.8 62.5 226.3 0s62.5-163.8 0-226.3s-163.8-62.5-226.3 0L125.7 160z"/>' +
          '</svg>',
        outlineID: 'pswp__icn-rotate',
      },
      appendTo: 'bar',
      onClick: (e, el, pswpInstance) => {
        if (!pswpInstance.currSlide?.content.element) {
          return;
        }

        const item = pswpInstance.currSlide.content.element;
        const prevRotateAngle = Number(item.dataset.rotateAngel) || 0;
        const rotateAngle = (prevRotateAngle - 90 + 360) % 360;
        item.style.transform = `rotate(${rotateAngle}deg)`;
        item.dataset.rotateAngel = String(rotateAngle);
      },
      onInit: (el, pswpInstance) => {
        pswpInstance.on('contentRemove', () => {
          if (!pswpInstance.currSlide?.content.element) {
            return;
          }

          const item = pswpInstance.currSlide.content.element;
          item.style.transform = '';
          delete item.dataset.rotateAngel;
        });
      },
    },
    {
      name: 'custom-rotate-button-right',
      ariaLabel: 'RotateRight',
      order: 9,
      isButton: true,
      html: {
        isCustomSVG: true,
        inner:
          '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" width="18" height="18">' +
          '<path d="M386.3 160H336c-17.7 0-32 14.3-32 32s14.3 32 32 32H464c17.7 0 32-14.3 32-32V64c0-17.7-14.3-32-32-32s-32 14.3-32 32v51.2L414.4 97.6c-87.5-87.5-229.3-87.5-316.8 0s-87.5 229.3 0 316.8s229.3 87.5 316.8 0c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0c-62.5 62.5-163.8 62.5-226.3 0s-62.5-163.8 0-226.3s163.8-62.5 226.3 0L386.3 160z" fill="none" stroke="#111111" stroke-width="40"/>' +
          '<path d="M386.3 160H336c-17.7 0-32 14.3-32 32s14.3 32 32 32H464c17.7 0 32-14.3 32-32V64c0-17.7-14.3-32-32-32s-32 14.3-32 32v51.2L414.4 97.6c-87.5-87.5-229.3-87.5-316.8 0s-87.5 229.3 0 316.8s229.3 87.5 316.8 0c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0c-62.5 62.5-163.8 62.5-226.3 0s-62.5-163.8 0-226.3s163.8-62.5 226.3 0L386.3 160z"/>' +
          '</svg>',
        outlineID: 'pswp__icn-rotate',
      },
      appendTo: 'bar',
      onClick: (e, el, pswpInstance) => {
        if (!pswpInstance.currSlide?.content.element) {
          return;
        }

        const item = pswpInstance.currSlide.content.element;
        const prevRotateAngle = Number(item.dataset.rotateAngel) || 0;
        const rotateAngle = (prevRotateAngle + 90) % 360;
        item.style.transform = `rotate(${rotateAngle}deg)`;
        item.dataset.rotateAngel = String(rotateAngle);
      },
      onInit: (el, pswpInstance) => {
        pswpInstance.on('contentRemove', () => {
          if (!pswpInstance.currSlide?.content.element) {
            return;
          }

          const item = pswpInstance.currSlide.content.element;
          item.style.transform = '';
          delete item.dataset.rotateAngel;
        });
      },
    },
    {
      name: 'custom-close-button',
      ariaLabel: 'Close',
      order: 10,
      html: {
        isCustomSVG: true,
        inner:
          '<path d="M24 10l-2-2-6 6-6-6-2 2 6 6-6 6 2 2 6-6 6 6 2-2-6-6z" id="pswp__icn-close"></path>',
      },
      isButton: true,
      appendTo: 'caption',
      onClick: (e, el, pswpInstance) => {
        const buttonElement = document.querySelector('.pswp__button--close');
        buttonElement.click();
      },
    }
  ]

  const smallItemStyles: React.CSSProperties = {
    cursor: 'pointer',
    objectFit: 'cover',
    width: '150px',
    height: '150px',
  }

  return (
    <Gallery withDownloadButton withCaption uiElements={uiElements}>
      <div
        style={{
          display: 'grid',
          gridTemplateColumns: 'repeat(3, 0fr)',
          gridGap: 10,
        }}
      >
        {images.map((item, key) =>
          <Item
            cropped
            original={item.url}
            caption={item.name}
            alt={item.name}
            //thumbnail={item.url}
            width={item.width ?? '3000'}
            height={item.height ?? '3000'}
            key={key}
          >
            {({ ref, open }) => <img ref={ref}
                                     onClick={open}
                                     src={item.url}
                                     alt={item.name}
                                     style={smallItemStyles}
            />
            }
          </Item>
        )}
      </div>
    </Gallery>
  )
}

export default GalleryImages
