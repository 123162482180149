import './App.scss';
import Home from "./pages/Home";
import { useEffect } from "react";
import WebFont from 'webfontloader'
import Login from "./pages/Auth/Login";
import 'bootstrap/dist/css/bootstrap.min.css';
import { Routes, Route } from "react-router-dom";
import ChangePass from "./pages/Auth/ChangePass";
import { createTheme, ThemeProvider } from "@mui/material";

const theme = createTheme({
  palette: {
    primary: {
      main: '#0059a9',
    },
  }
});

export default function App() {

  useEffect(() => {
    WebFont.load({
      google: {
        families: ['Droid Sans', 'Roboto', 'Chilanka']
      }
    })

  }, [])
  return (
    <ThemeProvider theme={theme}>
      <div className='app'>
        <Routes>
          <Route path="/login" element={<Login/>}/>
          <Route path="/change-password" element={<ChangePass/>}/>
          <Route index path="/" element={<Home/>}/>
        </Routes>
      </div>
    </ThemeProvider>

  );
}