import React from "react";

function Footer() {
  const fStyle = {
    height: "40px",
    bottom: 0,
    width: "100%",
    left: 0,
    fontSize: "14px",
    background: "white",
    padding: "0 15px",
    position: "sticky",
    marginButton: "auto",
  };
  return (
    <footer
      className="page-footer font-small text-black-50 d-flex justify-content-between align-items-center"
      style={fStyle}
    >
      <span className="footer-copyright float-start">
        © {new Date().getFullYear()} Copyright by Grow Trans . All rights
        reserved
      </span>
    </footer>
  );
}

export default Footer;
