// import App from "./App";
// import {StrictMode} from "react";
// import {createRoot} from "react-dom/client";
// import {BrowserRouter} from "react-router-dom";
//
// const rootElement = document.getElementById("root");
// const root = createRoot(rootElement);
//
//
// root.render(
//     <StrictMode>
//         <BrowserRouter>
//             <App/>
//         </BrowserRouter>
//     </StrictMode>
// );
import * as React from "react";
import {createRoot} from "react-dom/client";
import {BrowserRouter} from "react-router-dom";
import App from "./App";

const root = createRoot(document.getElementById("root"));

root.render(
    <React.StrictMode>
        <BrowserRouter>
            <App/>
        </BrowserRouter>
    </React.StrictMode>
);