import React, { useEffect, useState } from "react";
import { Button, Card, Form, Row } from "react-bootstrap";
import Logo from "../../assets/images/LogoGrow.svg";
import "../../assets/scss/login.scss";
import {
  getInfoUser,
  loginAPI,
  updateCompanyAPI,
} from "../../services/UserService";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate } from "react-router-dom";

function Login() {
  const navigate = useNavigate();
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [loggedIn, setLoggedIn] = useState(false);
  const [rememberMe, setRememberMe] = useState(false);

  useEffect(() => {
    const savedUsername = localStorage.getItem("username");
    const savedPassword = localStorage.getItem("password");
    const savedRememberMe = localStorage.getItem("rememberMe");

    if (savedRememberMe === "true") {
      setUsername(savedUsername || "");
      setPassword(savedPassword || "");
      setRememberMe(true);
    }
  }, []);

  const handleLogin = async () => {
    let basicAuth = "Basic " + btoa(username + ":" + password);
    if (!username || !password) {
      toast.error("Vui lòng nhập đầy đủ tên đăng nhập và mật khẩu.");
      return;
    }

    loginAPI(basicAuth)
      .then((response) => {
        if (rememberMe) {
          localStorage.setItem("username", username);
          localStorage.setItem("password", password);
          localStorage.setItem("rememberMe", rememberMe);
        } else {
          localStorage.removeItem("username");
          localStorage.removeItem("password");
          localStorage.removeItem("rememberMe");
        }
        try {
          setLoggedIn(false);
          localStorage.setItem("isLogin", "true");
          localStorage.setItem("token", response.data.response.token);
          getInfo(response.data.response.token);
        } catch (error) {
          localStorage.setItem("isLogin", "false");
          toast.error(
            "Tên đăng nhập hoặc mật khẩu không đúng, vui lòng kiểm tra lại."
          );
        }
      })
      .catch((e) => {
        toast.error(
          "Tên đăng nhập hoặc mật khẩu không đúng, vui lòng kiểm tra lại.."
        );
      });
  };

  function getInfo(token) {
    let data = JSON.stringify({
      query: [
        {
          LoginName: "==" + username,
        },
      ],
      limit: "100",
      offset: "1",
    });
    getInfoUser(data, token).then((response) => {
      let userId = response.data.response.data[0].recordId;
      let userKey = response.data.response.data[0].fieldData.PrimaryKey;
      let zcFullName = response.data.response.data[0].fieldData.zcFullName;
      let listCompany = response.data.response.data[0].fieldData.ListCompany;
      let isChange =
        response.data.response.data[0].fieldData.IsPasswordChangeNeeded;
      localStorage.setItem("userId", userId);
      localStorage.setItem("userKey", userKey);
      localStorage.setItem("userName", zcFullName);
      localStorage.setItem("listCompany", listCompany);
      if (isChange === 1) {
        navigate("/change-password");
      } else {
        updateCompany(userKey, token);
      }
    });
  }

  function updateCompany(userKey, token) {
    updateCompanyAPI(userKey, token).then((r) => {
      if (r.data.messages[0].message === "OK") {
        navigate("/");
      } else {
        toast.error("Có lỗi trong quá trình xử lý, vui lòng thử lại.");
      }
    });
  }

  return (
    <div style={{ height: "100vh" }}>
      {loggedIn ? (
        navigate("/")
      ) : (
        <div className={"d-flex h-100"}>
          <div className="m-auto align-items-center" style={{ width: "400px" }}>
            <Card className="border-0 p-3" style={{ borderRadius: "15px" }}>
              <Row className="mb-5">
                <div className="d-flex flex-row">
                  <div className="flex-grow-0">
                    <img src={Logo} alt="Logo" />
                  </div>
                  <div className="flex-grow-1 m-auto">
                    <strong>CÔNG TY VẬN TẢI GROW</strong>
                    <div style={{ fontSize: "12px", fontWeight: "500" }}>
                      Số 9 đường 7A, Phường Tăng Nhơn Phú B, TP. Thủ Đức, TP.
                      HCM
                    </div>
                  </div>
                </div>
              </Row>
              <Form className="w-100">
                <Form.Group className="mb-4" controlId="formBasicEmail">
                  <Form.Label>Tên đăng nhập</Form.Label>
                  <Form.Control
                    type="text"
                    value={username}
                    placeholder=""
                    onChange={(e) => setUsername(e.target.value)}
                  />
                </Form.Group>

                <Form.Group className="mb-4" controlId="formBasicPassword">
                  <Form.Label>Mật khẩu</Form.Label>
                  <Form.Control
                    type="password"
                    value={password}
                    placeholder=""
                    onChange={(e) => setPassword(e.target.value)}
                  />
                </Form.Group>
                <Form.Group className="mb-4" controlId="formBasicCheckbox">
                  <Form.Check
                    type="checkbox"
                    checked={rememberMe}
                    onChange={(e) => setRememberMe(e.target.checked)}
                    label="Ghi nhớ đăng nhập"
                  />
                </Form.Group>
                <Button
                  variant="primary"
                  className="w-100 btn-submit"
                  onClick={handleLogin}
                >
                  Đăng nhập
                </Button>
              </Form>
            </Card>
            <ToastContainer />
          </div>
        </div>
      )}
    </div>
  );
}

export default Login;
