import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import Notify from "../../src/assets/images/notify.svg";
import User from "../../src/assets/images/user.svg";
import Dropdown from "react-bootstrap/Dropdown";
import { PiUserBold, PiPowerBold } from "react-icons/pi";
import { useNavigate } from "react-router-dom";
import { logoutAPI } from "../services/UserService";
import "../assets/scss/header.scss";

function Header(props) {
  const { onAction } = props;
  const token = localStorage.getItem("token");
  const username = localStorage.getItem("userName");
  const nav = useNavigate();

  function onFilter(value) {
    if (onAction) {
      onAction(value);
    }
  }

  function logout() {
    logoutAPI(token).then((r) => console.log(r));
    localStorage.clear();
    nav("/login");
  }

  const brandStyle = {
    fontWeight: "bold",
  };
  return (
    <div className={"header-container"}>
      <Navbar expand="lg" className="bg-body-tertiary ">
        <Container className={"flex-nowrap m-0 mt-1"}>
          <Navbar.Brand href="#home" className="h4 m-0 me-4" style={brandStyle}>
            TRA CỨU VẬN CHUYỂN{" "}
          </Navbar.Brand>
          {/*<Navbar.Toggle aria-controls="basic-navbar-nav"/>*/}
          <Nav className="me-auto flex-nowrap flex-row">
            <Nav.Link
              className="text-primary"
              href="#"
              onClick={() => onFilter("yesterday")}
            >
              Hôm qua
            </Nav.Link>
            <Nav.Link
              className="text-primary"
              href="#"
              onClick={() => onFilter("today")}
            >
              Hôm nay
            </Nav.Link>
            <Nav.Link
              className="text-primary"
              href="#"
              onClick={() => onFilter("tomorrow")}
            >
              Ngày mai
            </Nav.Link>
            <Nav.Link
              className="text-primary"
              href="#"
              onClick={() => onFilter("thismonth")}
            >
              Tháng này
            </Nav.Link>
            <Nav.Link
              className="text-primary"
              href="#"
              onClick={() => onFilter("lastmonth")}
            >
              Tháng trước
            </Nav.Link>
          </Nav>
          <div className="d-flex">
            <Dropdown className={"float-end"}>
              <Dropdown.Toggle variant="" className={"custom-btn-icon"}>
                <img alt="User" src={User} className="custom-icon " />
              </Dropdown.Toggle>
              <Dropdown.Menu>
                <Dropdown.Item href="#">
                  <PiUserBold className="mb-1" />{" "}
                  <span className="text">{username}</span>
                </Dropdown.Item>
                <Dropdown.Item href="#" onClick={logout}>
                  <PiPowerBold className="mb-1" />{" "}
                  <span className="text">Logout</span>
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </div>
        </Container>
      </Navbar>
    </div>
  );
}

export default Header;
