import "../assets/scss/table.scss";
import classNames from "classnames";
import Footer from "../components/Footer";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import NewDataTable from "../components/NewDataTable";

function Home() {
  const navigate = useNavigate();
  const isLogin = localStorage.getItem("isLogin");
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    if (!isLogin || isLogin === false) {
      navigate("/login");
    }
    const mediaQuery = window.matchMedia("(max-width: 768px)"); // Điều chỉnh điều kiện kích thước màn hình tại đây
    setIsMobile(mediaQuery.matches);
    const resizeHandler = () => setIsMobile(mediaQuery.matches);
    mediaQuery.addEventListener("change", resizeHandler);
    return () => {
      mediaQuery.removeEventListener("change", resizeHandler);
    };
  }, [isLogin, navigate]);

  return (
    <div className={classNames("your-component", { "mobile-view": isMobile })}>
      <div className="home-container">
        <NewDataTable />
      </div>
    </div>
  );
}

export default Home;
