import React, { useEffect, useLayoutEffect, useRef, useState } from "react";
import { Table } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import Moment from "react-moment";
import "moment/locale/vi";
import moment from "moment";
import ReactPaginate from "react-paginate";
import LoadingIcons from "react-loading-icons";
import { ToastContainer, toast } from "react-toastify";
import {
  getData,
  getCompanyFound,
  getFile,
  getSiteShip,
} from "../services/UserService";
import iconFile from "../assets/images/iconFile.svg";
import iconImage from "../assets/images/iconImage.svg";
import {
  Button,
  DatePicker,
  Input,
  message,
  Modal,
  Popover,
  Select,
} from "antd";
import * as FileSaver from "file-saver";
import { saveAs } from "file-saver";
import iconJPG from "../assets/images/jpg-svgrepo-com.svg";
import iconPDF from "../assets/images/pdf-svgrepo-com.svg";
import iconDOWN from "../assets/images/download-svgrepo-com.svg";
import { FaRedo, FaSearchMinus, FaSearchPlus, FaUndo } from "react-icons/fa";
import { TfiReload } from "react-icons/tfi";
import Header from "./Header";
import dayjs from "dayjs";
import * as XLSX from "xlsx";
import { MapContainer, TileLayer } from "react-leaflet";
import RoutineMachine from "./RoutineMachine";
import GalleryImages from "./GalleryImages";
import { getImageSize } from "react-image-size";
import SiteShip from "./SiteShip";
import { FiChevronLeft, FiChevronRight } from "react-icons/fi";
import { IconContext } from "react-icons";
import { FormControl, Grid, MenuItem } from "@mui/material";
import { CustomTextFieldStyle } from "../styles/home/CustomStyle";
import Pagination from "@mui/material/Pagination";
import Typography from "@mui/material/Typography";
import useMediaQuery from "@mui/material/useMediaQuery";
import { Select as MuiSelect } from "@mui/material";

const DataTable = (props) => {
  const navigate = useNavigate();
  const isLogin = localStorage.getItem("isLogin");
  const listCompany = localStorage.getItem("listCompany");
  const limitRow = localStorage.getItem("limitRow")
    ? localStorage.getItem("limitRow")
    : 20;
  const dateFormat = "DD/MM/YYYY";
  const [limit, setLimit] = useState(Number(limitRow));
  if (!isLogin || isLogin === false) {
    navigate("/login");
  }

  const [index, setIndex] = useState(1);
  const [minIndex, setMinIndex] = useState(1);
  const [maxIndex, setMaxIndex] = useState(20);
  const [data, setData] = useState("");
  const token = localStorage.getItem("token");
  const userKey = localStorage.getItem("userKey");
  const [dataError, setDataError] = useState(
    <LoadingIcons.Puff stroke="#0059a9" />
  );
  const [pageNumbers, setPageNumber] = useState(0);
  const [forcePage, setForcePage] = useState(0);

  const [contNumber, setContNumber] = useState("");
  const [statusCont, setStatusCont] = useState("");
  const [company, setCompany] = useState("");
  const [companyListFound, setCompanyListFound] = useState("");
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [reset, setReset] = useState(true);

  //modal
  const [open, setOpen] = useState(false);
  const [openMap, setOpenMap] = useState(false);
  const [typeFile, setTypeFile] = useState("");
  const [urlFiles, setUrlFiles] = useState([]);
  const [urlImages, setUrlImages] = useState([
    {
      url: null,
      name: null,
      width: 0,
      height: 0,
    },
  ]);
  const [showSiteShip, setShowSiteShip] = useState(false);
  const [dataSiteShip, setDataSiteShip] = useState(false);
  const [showImages, setShowImages] = useState(false);
  const [fileShow, setFileShow] = useState("");
  const [fileName, setFileName] = useState("");

  const paginationRef = useRef(null);
  const [latStart, setLatStart] = useState(null);
  const [longStart, setLongStart] = useState(null);
  const [latEnd, setLatEnd] = useState(null);
  const [longEnd, setLongEnd] = useState(null);
  const [markerPopup, setMarkerPopup] = useState(null);
  const [markerPopupType, setMarkerPopupType] = useState(null);
  const [color, setColor] = useState("blue");
  const [currentPage, setCurrentPage] = useState(1);

  const [image, setImage] = useState({
    src: "",
    width: "100%",
    height: "100%",
    rotation: 0,
  });

  // active img
  const [zoom, setZoom] = useState(1);
  const [rotation, setRotation] = useState(0);
  const [showPagination, setShowPagination] = useState(true);

  const [widthXh, setWidthXh] = useState(0);
  const refXh1 = useRef(null);
  const refXh2 = useRef(null);
  const refXh3 = useRef(null);
  const refXh4 = useRef(null);
  const refXh5 = useRef(null);
  const refXh6 = useRef(null);
  const refXh7 = useRef(null);
  const refXh8 = useRef(null);
  const refXh9 = useRef(null);
  const refXh10 = useRef(null);

  const [widthXl, setWidthXl] = useState(0);
  const refXl1 = useRef(null);
  const refXl2 = useRef(null);

  const optionsData = [];
  if (companyListFound) {
    companyListFound.split("\r").map((value, index) => {
      optionsData.push({
        value: value,
        label: value,
      });
    });
  }

  function createName(url) {
    let name;
    const parts = url.split("?")[0];
    const part = parts.split("/");
    const fileName = part[part.length - 1];

    if (fileName.length >= 9) {
      name = fileName.slice(-9);
    } else {
      name = fileName;
    }
    return name;
  }

  function getDataTable(
    inputDate,
    inputCompany,
    inputCont,
    inputStatus,
    limit,
    offset
  ) {
    setData("");
    setDataError(<LoadingIcons.Puff stroke="#0059a9" />);
    getData(
      inputDate,
      inputCompany,
      inputCont,
      inputStatus,
      limit,
      offset,
      token
    )
      .then((res) => {
        if (res.data.messages[0].message === "OK") {
          setData(res.data.response.data);
          setPageNumber(res.data.response.dataInfo.foundCount);
          if (res.data.response.dataInfo.foundCount <= limit) {
            setShowPagination(false);
          } else {
            setShowPagination(true);
          }
        } else {
          setData("");
          setDataError(<LoadingIcons.Puff stroke="#0059a9" />);
        }
        // setTimeout(() => {
        //   setWidthXh(
        //     refXh1.current.offsetWidth +
        //       refXh2.current.offsetWidth +
        //       refXh3.current.offsetWidth +
        //       refXh4.current.offsetWidth +
        //       refXh5.current.offsetWidth +
        //       refXh6.current.offsetWidth +
        //       refXh7.current.offsetWidth +
        //       refXh8.current.offsetWidth
        //   );
        //   setWidthXl(refXl1?.current.offsetWidth + refXl2?.current.offsetWidth);
        // }, 500);
      })
      .catch((error) => {
        if (error.response.status === 401) {
          navigate("/login");
        } else if (error.response.status === 500) {
          setData("");
          setIndex(1);
          setPageNumber(0);
          setDataError("Không có dữ liệu tương ứng!");
        }
      });
  }

  const handleChangePage = (event, newPage) => {
    const formatStart = startDate
      ? startDate.format("MM/DD/YYYY").toString()
      : "";
    const formatEnd = endDate ? endDate.format("MM/DD/YYYY").toString() : "";
    const inputDate = formatStart + ".." + formatEnd;
    const inputCompany = company ? company : "*";
    const inputCont = contNumber ? `*${contNumber}*` : "*";
    const inputStatus = statusCont ? statusCont : "*";

    const offset = limitRow * (newPage - 1) + 1;
    setCurrentPage(offset);

    getDataTable(
      inputDate,
      inputCompany,
      inputCont,
      inputStatus,
      limitRow,
      offset
    );
  };

  const hidden = useMediaQuery((theme) => theme.breakpoints.down("md"));

  const handleChangeLimit = (value) => {
    setLimit(value);
    const formatStart = startDate
      ? startDate.format("MM/DD/YYYY").toString()
      : "";
    const formatEnd = endDate ? endDate.format("MM/DD/YYYY").toString() : "";
    const inputDate = formatStart + ".." + formatEnd;
    const inputCompany = company ? company : "*";
    const inputCont = contNumber ? `*${contNumber}*` : "*";
    const inputStatus = statusCont ? statusCont : "*";
    getDataTable(inputDate, inputCompany, inputCont, inputStatus, value, 1);
    localStorage.setItem("limitRow", value);
  };

  function getCompanyListFound() {
    setCompanyListFound("");
    getCompanyFound(token).then((res) => {
      if (res.data.messages[0].message === "OK") {
        // console.log(res.data.response.data[0].fieldData.zgCompanyFound);
        setCompanyListFound(res.data.response.data[0].fieldData.zgCompanyFound);
      } else {
        setCompanyListFound("");
      }
    });
  }

  function getWidthVehicle() {
    setWidthXh(0);
    setWidthXh(
      refXh1.current.offsetWidth +
        refXh2.current.offsetWidth +
        refXh3.current.offsetWidth +
        refXh4.current.offsetWidth +
        refXh5.current.offsetWidth +
        refXh6.current.offsetWidth +
        refXh7.current.offsetWidth +
        refXh8.current.offsetWidth +
        refXh9.current.offsetWidth +
        refXh10.current.offsetWidth
    );
    setWidthXl(0);
    setWidthXl(refXl1?.current.offsetWidth + refXl2?.current.offsetWidth);
  }

  const handleSubmit = async () => {
    const elements = document.getElementsByClassName("hide-element");
    for (var i = 0; i < elements.length; i++) {
      elements[i].style.setProperty("display", "block", "important");
    }
    setForcePage(0);
    if (startDate && endDate) {
      const diffDays = endDate.diff(startDate, "days");
      if (diffDays > 60) {
        toast.error("Khoảng thời gian không được quá 60 ngày");
        return;
      }
    } else {
      toast.error("Vui lòng nhập đầy đủ ngày bắt đầu và ngày kết thúc.");
      return;
    }

    const formatStart = startDate
      ? startDate.format("MM/DD/YYYY").toString()
      : "";
    const formatEnd = endDate ? endDate.format("MM/DD/YYYY").toString() : "";

    const inputDate = formatStart + ".." + formatEnd;
    const inputCompany = company ? company : "*";
    const inputCont = contNumber ? `*${contNumber}*` : "*";
    const inputStatus = statusCont ? statusCont : "*";

    if (formatStart && formatEnd) {
      setData("");
      setIndex(1);
      setMinIndex(1);
      setMaxIndex(20);
      setPageNumber(1);
      getDataTable(
        inputDate,
        inputCompany,
        inputCont,
        inputStatus,
        limitRow,
        1
      );
      getCompanyListFound();
    } else if ((startDate && !endDate) || (!startDate && endDate)) {
      toast.error("Vui lòng nhập đầy đủ ngày bắt đầu và ngày kết thúc.");
      setData("");
      setIndex(1);
      setPageNumber(1);
      setMinIndex(1);
      setMaxIndex(20);
      getDataTable(
        inputDate,
        inputCompany,
        inputCont,
        inputStatus,
        limitRow,
        1
      );
      getCompanyListFound();
    }
  };

  const handleSubmitWithAction = async (action) => {
    setForcePage(0);
    setData("");
    setIndex(1);
    setMinIndex(1);
    setMaxIndex(20);
    setPageNumber(1);
    const elements = document.getElementsByClassName("hide-element");
    for (var i = 0; i < elements.length; i++) {
      elements[i].style.setProperty("display", "block", "important");
    }
    let formatStart;
    let formatEnd;

    let today = moment();
    let thisMonth = moment([moment().year(), moment().month()]);

    switch (action) {
      case "yesterday":
        formatStart = moment(today).add(-1, "days");
        formatEnd = moment(today).add(-1, "days");
        break;
      case "today":
        formatStart = today;
        formatEnd = today;
        break;
      case "tomorrow":
        formatStart = moment(today).add(1, "days");
        formatEnd = moment(today).add(1, "days");
        break;
      case "thismonth":
        formatStart = moment([moment().year(), moment().month()]);
        formatEnd = moment(formatStart).endOf("month");
        break;
      case "lastmonth":
        formatStart = moment(thisMonth).add(-1, "months");
        formatEnd = moment(formatStart).endOf("month");
        break;
      default:
        formatStart = today;
        formatEnd = today;
        break;
    }

    let start = dayjs(
      moment(formatStart, "YYYY-MM-DD")
        .format()
        .toString(),
      "YYYY-MM-DD"
    );
    let end = dayjs(
      moment(formatEnd, "YYYY-MM-DD")
        .format()
        .toString(),
      "YYYY-MM-DD"
    );

    formatStart = moment(formatStart, "YYYY-MM-DD")
      .format("MM/DD/YYYY")
      .toString();
    formatEnd = moment(formatEnd, "YYYY-MM-DD")
      .format("MM/DD/YYYY")
      .toString();
    setStartDate(start);
    setEndDate(end);

    let stringDate = "*";
    if (formatStart && formatEnd) {
      stringDate = formatStart + ".." + formatEnd;
    }
    const inputDate = stringDate;
    const inputCompany = company ? company : "*";
    const inputCont = contNumber ? `*${contNumber}*` : "*";
    const inputStatus = statusCont ? statusCont : "*";
    getDataTable(inputDate, inputCompany, inputCont, inputStatus, limitRow, 1);
    getCompanyListFound();
  };

  const handleUpdate = async () => {
    setData("");
    setIndex(1);
    setMinIndex(1);
    setMaxIndex(20);
    setPageNumber(1);
    setDataError("Đang cập nhật...");
    setStartDate(
      dayjs(
        moment()
          .format("YYYY-MM-DD")
          .toString(),
        "YYYY-MM-DD"
      )
    );
    setEndDate(
      dayjs(
        moment()
          .format("YYYY-MM-DD")
          .toString(),
        "YYYY-MM-DD"
      )
    );
    const inputDate = `${moment().format("MM/DD/YYYY")}..${moment().format(
      "MM/DD/YYYY"
    )}`;
    getDataTable(inputDate, "*", "*", "*", limitRow, 1);
    getCompanyListFound();
  };

  const handlePageClick = (selected) => {
    setForcePage(selected.selected);
    const currentIndex = Math.min((selected.selected + 1) * 20, pageNumbers);
    let offset = currentIndex - 19;
    setMinIndex(selected.selected * 20 + 1);
    if (maxIndex + 20 <= (selected.selected + 1) * 20) {
      offset = (selected.selected + 1) * 20 - 19;
    }

    setIndex(offset);
    setMaxIndex(Math.min((selected.selected + 1) * 20, pageNumbers));
    const formatStart = startDate
      ? startDate.format("MM/DD/YYYY").toString()
      : "";
    const formatEnd = endDate ? endDate.format("MM/DD/YYYY").toString() : "";

    let stringDate = "*";
    if (formatStart && formatEnd) {
      stringDate = formatStart + ".." + formatEnd;
    }

    const inputDate = stringDate;
    const inputCompany = company ? company : "*";
    const inputCont = contNumber ? `*${contNumber}*` : "*";
    const inputStatus = statusCont ? statusCont : "*";
    if (offset === 0) {
      offset = 1;
    }
    // console.log(offset, (selected.selected + 1) * 20)
    // console.log(inputDate)
    getDataTable(
      inputDate,
      inputCompany,
      inputCont,
      inputStatus,
      limitRow,
      offset
    );
  };

  function renameAndDownloadFile(originalUrl, newFileName) {
    fetch(originalUrl)
      .then((response) => response.blob())
      .then((blob) => {
        const renamedBlob = new Blob([blob], { type: blob.type });
        saveAs(renamedBlob, newFileName);
      })
      .catch((error) => {
        console.error("Error downloading the file:", error);
      });
  }

  function showModal(recordId, type) {
    setFileShow("");
    setOpen(true);
    getFile(recordId, token)
      .then((response) => response)
      .then((data) => {
        setUrlFiles([]);
        const newUrlFiles = [];
        let root = data.data.response.data[0];
        setTypeFile("pdf");
        if (type === "BKF") {
          for (let i = 1; i <= 3; i++) {
            const url = root.fieldData[`Booking::BOOKING_FILE(${i})`];
            if (url) {
              newUrlFiles.push({
                url: url,
                name: createName(root.fieldData[`Booking::BOOKING_FILE(${i})`]),
              });
            }
          }
          setUrlFiles(newUrlFiles);
          setFileShow(newUrlFiles[0]?.url);
          setFileName(createName(newUrlFiles[0]?.url));
        } else if (type === "PKL") {
          for (let i = 1; i <= 3; i++) {
            const url = root.fieldData[`PKL_FILE(${i})`];
            if (url) {
              newUrlFiles.push({
                url: url,
                name: createName(root.fieldData[`PKL_FILE(${i})`]),
              });
            }
          }
          setUrlFiles(newUrlFiles);
          setFileShow(newUrlFiles[0]?.url);
          setFileName(createName(newUrlFiles[0]?.url));
        }
      });
  }

  function PreviewImage(recordId, type) {
    const newUrlFiles = [];
    getFile(recordId, token)
      .then(async (data) => {
        let root = data.data.response.data[0];
        if (type === "HKR") {
          for (let i = 1; i <= 10; i++) {
            const url = root.fieldData[`HINH_KEO_CONT_RONG(${i})`];
            if (url) {
              const dimensions = await getImageSize(url);
              // console.log(dimensions);
              newUrlFiles.push({
                url: url,
                name: createName(root.fieldData[`HINH_KEO_CONT_RONG(${i})`]),
                width: dimensions?.width,
                height: dimensions?.height,
              });
            }
          }
        } else if (type === "HKNM") {
          for (let i = 1; i <= 3; i++) {
            const url = root.fieldData[`HINH_KEO_NHA_MAY(${i})`];
            if (url) {
              const dimensions = await getImageSize(url);
              newUrlFiles.push({
                url: url,
                name: createName(root.fieldData[`HINH_KEO_NHA_MAY(${i})`]),
                width: dimensions?.width,
                height: dimensions?.height,
              });
            }
          }
        } else if (type === "HHNM") {
          const url = root.fieldData[`HINH_HA_NHA_MAY(1)`];
          if (url) {
            const dimensions = await getImageSize(url);
            newUrlFiles.push({
              url: url,
              name: createName(root.fieldData[`HINH_HA_NHA_MAY(1)`]),
              width: dimensions?.width,
              height: dimensions?.height,
            });
          }
        } else if (type === "HHHH") {
          for (let i = 1; i <= 10; i++) {
            const url = root.fieldData[`HINH_HA_HA_HANG(${i})`];
            if (url) {
              const dimensions = await getImageSize(url);
              newUrlFiles.push({
                url: url,
                name: createName(root.fieldData[`HINH_HA_HA_HANG(${i})`]),
                width: dimensions?.width,
                height: dimensions?.height,
              });
            }
          }
        } else if (type === "VGM") {
          const url = root.fieldData["HINH_HA_NHA_MAY(1)"];
          if (url) {
            const dimensions = await getImageSize(url);
            newUrlFiles.push({
              url: url,
              name: createName(root.fieldData[`HINH_HA_NHA_MAY(1)`]),
              width: dimensions?.width,
              height: dimensions?.height,
            });
          }
        }
      })
      .finally(() => {
        if (newUrlFiles.length > 0) {
          setUrlImages(newUrlFiles);
          setShowImages(true);

          setTimeout(() => {
            setReset(!reset);
          }, 500);
        }
      });
  }

  const handleZoomIn = () => {
    setZoom(zoom + 0.1);
  };

  const handleZoomOut = () => {
    setZoom(zoom - 0.1);
  };

  const handleRotateLeft = () => {
    const newRotation = (image.rotation + 90) % 360;
    const newWidth = image.height;
    const newHeight = image.width;

    setImage({
      ...image,
      rotation: newRotation,
      width: newWidth,
      height: newHeight,
    });
    setRotation(rotation - 90);
  };

  const handleRotateRight = () => {
    setRotation(rotation + 90);
  };

  const handleDownFile = (l) => {
    renameAndDownloadFile(l, createName(l));
  };

  function hideElementOnSelect() {
    const elements = document.getElementsByClassName("hide-element");
    for (var i = 0; i < elements.length; i++) {
      elements[i].style.setProperty("display", "none", "important");
    }
  }

  function onSelectStartDate(date, dateString) {
    setStartDate(date);
    setData("");
    setDataError("");
    hideElementOnSelect();
  }

  function onSelectEndDate(date, dateString) {
    setEndDate(date);
    setData("");
    setDataError("");
    hideElementOnSelect();
  }

  const onContNumber = (value) => {
    setData("");
    setDataError("");
    hideElementOnSelect();
    if (value) {
      setContNumber(value);
    } else {
      setContNumber("*");
    }
  };

  const changeStatusCont = (value) => {
    setData("");
    setDataError("");
    hideElementOnSelect();
    setStatusCont(value);
  };

  const onChangeCompany = (value) => {
    setData("");
    setDataError("");
    hideElementOnSelect();
    if (value) {
      setCompany(value);
    } else {
      setCompany("*");
    }
  };

  const onSearchCompany = (value: string) => {
    // console.log("search:", value);
  };

  const filterOption = (
    input: string,
    option?: {
      label: string,
      value: string,
    }
  ) => (option?.label ?? "").toLowerCase().includes(input.toLowerCase());

  // Preview Image
  const containerRef = useRef(null);
  const imgRef = useRef(null);

  const zoom_now = () => {
    const container = containerRef.current;
    const img = imgRef.current;

    let zoom = 1;

    container.addEventListener("wheel", (e) => {
      img.style.transformOrigin = `${e.offsetX}px ${e.offsetY}px`;

      zoom += e.deltaY * -0.01;
      zoom = Math.min(Math.max(1, zoom), 5);

      if (zoom === 1) {
        img.style.left = "0px";
        img.style.top = "0px";
      }

      img.style.transform = `scale(${zoom})`;
    });

    let clicked = false;
    let xAxis;
    let x;
    let yAxis;
    let y;

    container.addEventListener(
      "mouseup",
      () => (container.style.cursor = "auto")
    );

    container.addEventListener("mousedown", (e) => {
      clicked = true;
      xAxis = e.offsetX - img.offsetLeft;
      yAxis = e.offsetY - img.offsetTop;

      container.style.cursor = "grabbing";
    });

    window.addEventListener("mouseup", () => (clicked = false));

    container.addEventListener("mousemove", (e) => {
      if (!clicked) return;
      e.preventDefault();

      x = e.offsetX;
      y = e.offsetY;

      img.style.left = `${x - xAxis}px`;
      img.style.top = `${y - yAxis}px`;

      checkSize();
    });

    function checkSize() {
      let containerOut = container.getBoundingClientRect();
      let imgIn = img.getBoundingClientRect();

      if (parseInt(img.style.left) > 0) {
        img.style.left = "0px";
      } else if (imgIn.right < containerOut.right) {
        img.style.left = `-${imgIn.width - containerOut.width}px`;
      }
      if (parseInt(img.style.top) > 0) {
        img.style.top = "0px";
      } else if (imgIn.bottom < containerOut.bottom) {
        img.style.top = `-${imgIn.height - containerOut.height}px`;
      }
    }
  };

  function callFunctionWhenClassAppears() {
    const container = document.querySelector(".container-preview-image");
    if (container) {
      zoom_now();
    } else {
      setTimeout(callFunctionWhenClassAppears, 100);
    }
  }

  const showMap = (data, dColor, markerPopup, markerPopupType) => {
    setOpenMap(true);

    setTimeout(() => {
      let location_map = data.toString().replaceAll(",", ";");
      let replace = location_map
        .replaceAll("; ", "&")
        .replaceAll(";", ",")
        .split("&");

      let long_start = JSON.parse(replace[0])[0];
      let lat_start = JSON.parse(replace[0])[1];
      let long_end = JSON.parse(replace[1])[0];
      let lat_end = JSON.parse(replace[1])[1];

      setColor(dColor);
      setLatStart(lat_start);
      setLongStart(long_start);
      setLatEnd(lat_end);
      setLongEnd(long_end);
      setMarkerPopup(markerPopup);
      setMarkerPopupType(markerPopupType);
    }, 500);
  };

  const closeMap = () => {
    setColor(null);
    setLatStart(null);
    setLongStart(null);
    setLatEnd(null);
    setLongEnd(null);
    setOpenMap(false);
    setMarkerPopup(null);
  };

  const exportToCSV = () => {
    const newRandomNumbers = Array.from({ length: 3 }, () =>
      Math.floor(Math.random() * 100)
    );
    const joinedNumbers = newRandomNumbers.join("");
    const filename = "tracking_cont_" + joinedNumbers;
    const fileType =
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
    const fileExtension = ".xlsx";

    const formatStart = startDate
      ? startDate.format("MM/DD/YYYY").toString()
      : "";
    const formatEnd = endDate ? endDate.format("MM/DD/YYYY").toString() : "";

    let stringDate = "*";
    if (formatStart && formatEnd) {
      stringDate = formatStart + ".." + formatEnd;
    }
    const inputDate = stringDate;
    const inputCompany = company ? company : "*";
    const inputCont = contNumber ? contNumber : "*";
    const inputStatus = statusCont ? statusCont : "*";
    const limit = 1000;
    const offset = 1;

    getData(
      inputDate,
      inputCompany,
      inputCont,
      inputStatus,
      limit,
      offset,
      token
    )
      .then((res) => {
        if (res.data.messages[0].message === "OK") {
          let results = res.data.response.data;
          if (results && results.length > 0) {
            // console.log(results);
            let records = [];
            records = results.map((row, key) => ({
              STT: key + 1,
              NGAY_KEO_CONT: row.fieldData.NGAY,
              KHACH_HANG: row.fieldData.CONG_TY,
              SO_BOOKING: row.fieldData.SO_BOOKING,
              FILE_BOOKING: row.fieldData.zcBookingFile,
              GIO_SL: row.fieldData.SI_CUT_OFF,
              NOI_LAY_RONG: row.fieldData.NOI_LAY_CONT,
              NOI_DONG_HANG: row.fieldData.NOI_DONG_HANG,
              DA_LAY_KHO: row.fieldData.zcHinhKeoRong,
              DA_DEN_NHA_MAY: row.fieldData.zcHinhKeoNhaMay,
              TG_KEO: row.fieldData.TG_KEO,
              SO_CONT: row.fieldData.SO_CONT,
              SO_SEAL: row.fieldData.SO_SEAL,
              SO_MAX_GROSS: row.fieldData.SO_MAX_GROSS,
              SO_TARE: row.fieldData.SO_TARE,
              CONT_XONG: row.fieldData.CONT_XONG_TEXT,
              TK_XONG: row.fieldData.TK_XONG_TEXT,
              NOI_HA_CONT: row.fieldData.NOI_HA,
              CUT_OFF: row.fieldData.CUT_OFF,
              PKL_HA: row.fieldData.zcPKLFile,
              VGM: row.fieldData.zcVGMFile,
              HINH_DA_HA: row.fieldData.zcHinhHaHaHang,
              THOI_GIAN_HA: row.fieldData.TG_HA_XONG,
              TEN_TAU: row.fieldData.TEN_TAU,
              SO_CHUYEN: row.fieldData.SO_CHUYEN,
              CHUYEN_TAI: row.fieldData.CHUYEN_TAI,
              CANG_DEN: row.fieldData.CANG_DEN,
            }));

            const Heading = [Object.keys(records[0])];
            const ws = XLSX.utils.book_new();
            XLSX.utils.sheet_add_aoa(ws, Heading);
            XLSX.utils.sheet_add_json(ws, records, {
              origin: "A2",
              skipHeader: true,
            });
            const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
            const excelBuffer = XLSX.write(wb, {
              bookType: "xlsx",
              type: "array",
            });
            const data = new Blob([excelBuffer], { type: fileType });
            FileSaver.saveAs(data, filename + fileExtension);
          }
        } else {
        }
      })
      .catch((error) => {
        // console.log(error);
      });
  };

  useEffect(() => {
    setStartDate(
      dayjs(
        moment()
          .format("YYYY-MM-DD")
          .toString(),
        "YYYY-MM-DD"
      )
    );
    setEndDate(
      dayjs(
        moment()
          .format("YYYY-MM-DD")
          .toString(),
        "YYYY-MM-DD"
      )
    );
    const inputDate = `${moment().format("MM/DD/YYYY")}..${moment().format(
      "MM/DD/YYYY"
    )}`;
    getDataTable(inputDate, "*", "*", "*", limitRow, 1);
    getCompanyListFound();
    getSiteShip().then((res) => {
      // console.log(res);
      localStorage.setItem("optionSiteShip", JSON.stringify(res.data.response));
    });
  }, [isLogin, navigate, token, userKey]);

  useEffect(() => {
    getWidthVehicle();
  });

  const handleDataSiteShip = async (noiHa, tenTau) => {
    await setDataSiteShip({
      noiHa: noiHa,
      tenTau: tenTau,
    });
    if (localStorage.getItem("optionSiteShip")) {
      setShowSiteShip(true);
    }
  };

  const dpStyle = {
    maxWidth: "150px",
  };
  const fStyle = {
    fontSize: "14px",
    padding: "0px",
  };
  return (
    <div>
      <Header onAction={handleSubmitWithAction} />
      <Modal
        className="text-center"
        title={[
          fileName && fileShow ? (
            <div>
              <Button
                className={"bg-white border-0"}
                onClick={() => handleDownFile(fileShow)}
              >
                <img src={iconDOWN} alt="" width="25" height="25" />
              </Button>
              <span className="h6">{fileName}</span>
            </div>
          ) : (
            <LoadingIcons.Puff stroke="#0059a9" />
          ),
        ]}
        centered
        open={open}
        onOk={() => setOpen(false)}
        onCancel={() => setOpen(false)}
        destroyOnClose={true}
        width={"80vw"}
        footer={[
          <div className="m-auto text-center" key={1}>
            {typeFile === "img" ? (
              <div className="mb-2">
                <Button onClick={handleZoomIn} className="border-0">
                  <FaSearchPlus />
                </Button>{" "}
                <Button onClick={handleZoomOut} className="border-0">
                  <FaSearchMinus />
                </Button>{" "}
                <Button onClick={handleRotateLeft} className="border-0">
                  <FaUndo />
                </Button>{" "}
                <Button onClick={handleRotateRight} className="border-0">
                  <FaRedo />
                </Button>{" "}
              </div>
            ) : (
              ""
            )}
            {urlFiles.length ? (
              urlFiles.map((val, key) => {
                return (
                  <Button
                    className="custom-btn-change-file"
                    key={key}
                    onClick={() => setFileShow(urlFiles[key].url)}
                  >
                    {typeFile === "img" ? (
                      <img src={iconJPG} alt="" width="100%" height="100%" />
                    ) : (
                      <img src={iconPDF} alt="" width="100%" height="100%" />
                    )}
                  </Button>
                );
              })
            ) : (
              <LoadingIcons.Puff stroke="#0059a9" />
            )}
          </div>,
        ]}
      >
        {typeFile && fileShow ? (
          <object
            data={fileShow}
            type="application/pdf"
            width="100%"
            height="100%"
          />
        ) : (
          <LoadingIcons.Puff stroke="#0059a9" />
        )}
      </Modal>

      <Modal
        open={showImages}
        footer={null}
        onCancel={() => setShowImages(false)}
        width={550}
      >
        <GalleryImages images={urlImages} reset={reset} />
      </Modal>

      <Modal
        open={showSiteShip}
        className={"ant-modal-full-screen"}
        header={null}
        footer={null}
        onCancel={() => setShowSiteShip(false)}
      >
        {dataSiteShip ? (
          <SiteShip dataSiteShip={dataSiteShip} />
        ) : (
          <LoadingIcons.Puff stroke="#0059a9" />
        )}
      </Modal>

      <div className="custom-bg-filter">
        <div className="d-flex flex-row flex-nowrap overflow-auto">
          <div className="flex-grow-1 pe-2 ps-0">
            SỐ CONT/BK
            <br />
            <Input
              className="ant-input"
              type="text"
              placeholder="Nhập số CONT/BK"
              onChange={(event) => onContNumber(event.target.value)}
              allowClear
            />
          </div>
          <div className="flex-grow-1 pe-2">
            CÔNG TY <br />
            <Select
              showSearch
              allowClear
              placeholder="Chọn công ty"
              optionFilterProp="children"
              onChange={onChangeCompany}
              onSearch={onSearchCompany}
              filterOption={filterOption}
              options={optionsData}
            />
          </div>
          <div className="flex-grow-1 pe-2">
            TỪ NGÀY <br />
            <DatePicker
              placeholder="Chọn ngày"
              className={"input-date"}
              onChange={onSelectStartDate}
              value={startDate}
              format={dateFormat}
              name="startDate"
              style={dpStyle}
            />
          </div>
          <div className="flex-grow-1 pe-2">
            ĐẾN NGÀY <br />
            <DatePicker
              placeholder="Chọn ngày"
              className={"input-date"}
              onChange={onSelectEndDate}
              value={endDate}
              format={dateFormat}
              name="endDate"
              style={dpStyle}
            />
          </div>
          <div className="flex-grow-1 pe-4">
            TRẠNG THÁI CONT <br />
            <Select
              placeholder="TẤT CẢ"
              onChange={changeStatusCont}
              className={"tt-cont"}
            >
              <option>TẤT CẢ</option>
              <option value="0..0.1">CHƯA KÉO</option>
              <option value="0.2..2">CHƯA XONG</option>
              <option value="3..3.4">CHƯA HẠ</option>
              <option value="4">ĐÃ HẠ</option>
            </Select>
          </div>
          <div className="button-container d-flex flex-row flex-nowrap me-0 pe-0 w-100">
            <Button
              className="custom-btn custom-btn-primary me-3"
              size="sm"
              onClick={handleSubmit}
            >
              Tra cứu
            </Button>{" "}
            <Button
              className="custom-btn custom-btn-outline-primary ms-2 me-3"
              size="sm"
              variant="outline-primary"
              onClick={exportToCSV}
            >
              Xuất Excel
            </Button>{" "}
            <Button
              className="custom-btn-update custom-btn-primary"
              size="sm"
              variant="outline-primary"
              onClick={handleSubmit}
            >
              <TfiReload className="me-1" />
              Cập nhật
            </Button>
          </div>
        </div>
      </div>

      <div className="custom-table-sr">
        <Table bordered hover responsive="sm" className="custom-table">
          <thead>
            <tr>
              <th rowSpan="2">STT</th>
              <th rowSpan="2">Ngày kéo Cont</th>
              <th rowSpan="2">Khách hàng</th>
              <th>Số BK</th>
              <th>File BK</th>
              <th>Giờ SI</th>
              <th ref={refXl1}>Nơi lấy rỗng</th>
              <th ref={refXl2}>Cắt kho</th>
              <th ref={refXh1}>Đã lấy Cont</th>
              <th ref={refXh2}>Đến nhà máy</th>
              <th ref={refXh3}>Thời gian kéo</th>
              <th ref={refXh4}>Số Cont</th>
              <th ref={refXh5}>Số Seal</th>
              <th ref={refXh6}>Số Max Gross</th>
              <th ref={refXh7}>Số Tare</th>
              <th ref={refXh8}>Cont xong</th>
              <th ref={refXh9}>TK xong</th>
              <th ref={refXh10}>Nơi hạ Cont</th>
              <th>Giờ Cut off</th>
              <th>PKL hạ</th>
              <th>VGM</th>
              <th>Hình đã hạ</th>
              <th>Thời gian hạ</th>
              <th>Tên tàu</th>
              <th>Số chuyến</th>
              <th>Cảng chuyển tải</th>
              <th>Cảng đến</th>
            </tr>
          </thead>
          <tbody>
            {data ? (
              data.map((val, key) => {
                let showXeKeo = false;
                let showXeHa = false;

                let zcStatusValue = val.fieldData.zcStatusValue;

                let DistanceKeoPercent = val.fieldData.DistanceKeoPercent;
                let EngineStatus_Keo = val.fieldData.EngineStatus_Keo;

                let DistanceHaPercent = val.fieldData.DistanceHaPercent;
                let EngineStatus_Ha = val.fieldData.EngineStatus_Ha;

                if (
                  zcStatusValue >= 0.2 &&
                  zcStatusValue < 1 &&
                  DistanceKeoPercent >= 0
                ) {
                  showXeKeo = true;
                }
                if (
                  zcStatusValue >= 3.2 &&
                  zcStatusValue < 4 &&
                  DistanceHaPercent >= 0
                ) {
                  showXeHa = true;
                }

                return (
                  <tr key={key}>
                    <td className="text-center">{index + key}</td>
                    <td>
                      <Moment format="DD/MM/YYYY">{val.fieldData.NGAY}</Moment>
                    </td>
                    <td>{val.fieldData.CONG_TY}</td>
                    <td>{val.fieldData.SO_BOOKING}</td>
                    <td className={"text-center"}>
                      {val.fieldData.zcBookingFile > 0 ? (
                        <button
                          className={"btn btn-sm p-0"}
                          onClick={() => showModal(val.recordId, "BKF")}
                        >
                          <img src={iconFile} alt={"FILE"} width={16} />
                        </button>
                      ) : (
                        ""
                      )}
                    </td>
                    <td>
                      <Moment format="DD/MM/YYYY HH:mm">
                        {val.fieldData.SI_CUT_OFF}
                      </Moment>
                    </td>
                    <td
                      className={`xe-keo`}
                      style={
                        showXeKeo
                          ? { borderBottom: "3px dashed #e6e6e6" }
                          : null
                      }
                    >
                      {val.fieldData.NOI_LAY_CONT}

                      {showXeKeo ? (
                        <div style={{ position: "relative" }}>
                          <div
                            className="star-point"
                            style={{
                              position: "absolute",
                              width: "100%",
                              left: "-0.5rem",
                              margin: "-3px",
                              textAlign: "left",
                            }}
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="10"
                              height="3"
                            >
                              <rect width="10" height="3" fill="#1F9554"></rect>
                            </svg>
                          </div>
                          <div
                            style={{
                              position: "absolute",
                              marginTop: "-10px",
                              marginLeft: `${((widthXl - 15) / 100) *
                                (100 - DistanceKeoPercent)}px`,
                            }}
                          >
                            <Popover
                              content={
                                <>
                                  <ul className={"list-unstyled"}>
                                    <li className={"ms-2"}>
                                      Biển số xe:{" "}
                                      {val.fieldData.VehicleNumber_Keo}
                                    </li>
                                    <li className={"ms-2"}>
                                      Tên tài xế:{" "}
                                      {
                                        val.fieldData[
                                          "BL_Tracking_TXKeo::zcFullName"
                                        ]
                                      }
                                    </li>
                                    <li className={"ms-2"}>
                                      SĐT:{" "}
                                      {val.fieldData["BL_Tracking_TXKeo::Phone"]
                                        ? val.fieldData[
                                            "BL_Tracking_TXKeo::Phone"
                                          ]
                                        : ""}
                                    </li>
                                    <li className={"ms-2"}>
                                      Quãng đường:{" "}
                                      {(() => {
                                        try {
                                          return val.fieldData?.DistanceXeKho
                                            ? `${val.fieldData.DistanceXeKho.toFixed(
                                                1
                                              )}Km`
                                            : "";
                                        } catch (error) {
                                          return "";
                                        }
                                      })()}
                                    </li>
                                    <li className={"ms-2"}>
                                      Thời gian dự kiến:{" "}
                                      {val.fieldData.TimeXeKhoText}
                                    </li>
                                  </ul>
                                  <a
                                    href="#"
                                    onClick={() =>
                                      showMap(
                                        val.fieldData.CoordinatesKeo,
                                        EngineStatus_Keo === "ON"
                                          ? "#1F9554"
                                          : "#D64955",
                                        val.fieldData,
                                        0
                                      )
                                    }
                                  >
                                    Xem trên bản đồ
                                  </a>
                                </>
                              }
                              title="Thông tin"
                            >
                              <svg
                                data-type="1"
                                style={{ cursor: "pointer" }}
                                xmlns="http://www.w3.org/2000/svg"
                                height="16"
                                viewBox="0 -960 960 960"
                                width="20"
                              >
                                <path
                                  fill={
                                    EngineStatus_Keo === "ON"
                                      ? "#1F9554"
                                      : "#D64955"
                                  }
                                  d="M224.118-161Q175-161 140.5-195 106-229 106-279h-6q-24.75 0-42.375-17.625T40-339v-401q0-24 18-42t42-18h519q24.75 0 42.375 17.625T679-740v107h90q7.5 0 13.5 3t10.5 9l121 161q3 3.75 4.5 8.25T920-442v133q0 12.75-8.625 21.375T890-279h-41q0 50-34.382 84-34.383 34-83.5 34Q682-161 647.5-195 613-229 613-279H342q0 50-34.382 84-34.383 34-83.5 34ZM224-221q24 0 41-17t17-41q0-24-17-41t-41-17q-24 0-41 17t-17 41q0 24 17 41t41 17Zm507 0q24 0 41-17t17-41q0-24-17-41t-41-17q-24 0-41 17t-17 41q0 24 17 41t41 17Zm-52-204h186L754-573h-75v148Z"
                                ></path>
                              </svg>
                            </Popover>
                          </div>
                        </div>
                      ) : null}
                    </td>
                    <td
                      className={`xe-keo`}
                      style={
                        showXeKeo
                          ? { borderBottom: "3px dashed #e6e6e6" }
                          : null
                      }
                    >
                      {val.fieldData.NOI_DONG_HANG}

                      {showXeKeo && (
                        <div style={{ position: "relative" }}>
                          <div
                            className="end-point"
                            style={{
                              position: "absolute",
                              width: "100%",
                              right: "-0.5rem",
                              margin: "-3px",
                              textAlign: "right",
                            }}
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="10"
                              height="3"
                            >
                              <rect width="10" height="3" fill="#1F9554"></rect>
                            </svg>
                          </div>
                        </div>
                      )}
                    </td>
                    <td
                      className={`xe-ha text-center`}
                      style={
                        showXeHa ? { borderBottom: "3px dashed #e6e6e6" } : null
                      }
                    >
                      {val.fieldData.zcHinhKeoRong > 0 ? (
                        <button
                          className={"btn btn-sm p-0"}
                          onClick={() => PreviewImage(val.recordId, "HKR")}
                        >
                          <img src={iconImage} alt={"IMAGE"} width={16} />
                        </button>
                      ) : (
                        ""
                      )}

                      {showXeHa ? (
                        <div style={{ position: "relative" }}>
                          <div
                            className="star-point"
                            style={{
                              position: "absolute",
                              width: "100%",
                              left: "-0.5rem",
                              margin: "-3px",
                              textAlign: "left",
                            }}
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="10"
                              height="3"
                            >
                              <rect width="10" height="3" fill="#1F9554"></rect>
                            </svg>
                          </div>
                          <div
                            style={{
                              position: "absolute",
                              marginTop: "-10px",
                              marginLeft: `${((widthXh - 15) / 100) *
                                (100 - DistanceHaPercent)}px`,
                            }}
                          >
                            <Popover
                              content={
                                <>
                                  <ul className={"list-unstyled"}>
                                    <li className={"ms-2"}>
                                      Biển số xe:{" "}
                                      {val.fieldData.VehicleNumber_Ha}
                                    </li>
                                    <li className={"ms-2"}>
                                      Tên tài xế:{" "}
                                      {
                                        val.fieldData[
                                          "BL_Tracking_TXHa::zcFullName"
                                        ]
                                      }
                                    </li>
                                    <li className={"ms-2"}>
                                      SĐT:{" "}
                                      {val.fieldData["BL_Tracking_TXHa::Phone"]}
                                    </li>
                                    <li className={"ms-2"}>
                                      Quãng đường:{" "}
                                      {(() => {
                                        try {
                                          return val.fieldData?.DistanceXeCang
                                            ? `${val.fieldData.DistanceXeCang.toFixed(
                                                1
                                              )}Km`
                                            : "";
                                        } catch (error) {
                                          return "";
                                        }
                                      })()}
                                    </li>
                                    <li className={"ms-2"}>
                                      Thời gian dự kiến:{" "}
                                      {val.fieldData.TimeXeCangText}
                                    </li>
                                  </ul>
                                  <a
                                    href="#"
                                    onClick={() =>
                                      showMap(
                                        val.fieldData.CoordinatesHa,
                                        EngineStatus_Ha === "ON"
                                          ? "#1F9554"
                                          : "#D64955",
                                        val.fieldData,
                                        1
                                      )
                                    }
                                  >
                                    Xem trên bản đồ
                                  </a>
                                </>
                              }
                              title="Thông tin"
                            >
                              <svg
                                data-type="1"
                                style={{ cursor: "pointer" }}
                                xmlns="http://www.w3.org/2000/svg"
                                height="16"
                                viewBox="0 -960 960 960"
                                width="20"
                              >
                                <path
                                  fill={
                                    EngineStatus_Ha === "ON"
                                      ? "#1F9554"
                                      : "#D64955"
                                  }
                                  d="M224.118-161Q175-161 140.5-195 106-229 106-279h-6q-24.75 0-42.375-17.625T40-339v-401q0-24 18-42t42-18h519q24.75 0 42.375 17.625T679-740v107h90q7.5 0 13.5 3t10.5 9l121 161q3 3.75 4.5 8.25T920-442v133q0 12.75-8.625 21.375T890-279h-41q0 50-34.382 84-34.383 34-83.5 34Q682-161 647.5-195 613-229 613-279H342q0 50-34.382 84-34.383 34-83.5 34ZM224-221q24 0 41-17t17-41q0-24-17-41t-41-17q-24 0-41 17t-17 41q0 24 17 41t41 17Zm507 0q24 0 41-17t17-41q0-24-17-41t-41-17q-24 0-41 17t-17 41q0 24 17 41t41 17Zm-52-204h186L754-573h-75v148Z"
                                ></path>
                              </svg>
                            </Popover>
                          </div>
                        </div>
                      ) : null}
                    </td>
                    <td
                      className={`xe-ha text-center`}
                      style={
                        showXeHa ? { borderBottom: "3px dashed #e6e6e6" } : null
                      }
                    >
                      {val.fieldData.zcHinhKeoNhaMay > 0 ? (
                        <button
                          className={"btn btn-sm p-0"}
                          onClick={() => PreviewImage(val.recordId, "HKNM")}
                        >
                          <img src={iconImage} alt={"IMAGE"} width={16} />
                        </button>
                      ) : (
                        ""
                      )}
                    </td>
                    <td
                      className={`xe-ha`}
                      style={
                        showXeHa ? { borderBottom: "3px dashed #e6e6e6" } : null
                      }
                    >
                      {val.fieldData.TG_KEO && (
                        <Moment format="DD/MM/YYYY HH:mm">
                          {val.fieldData.TG_KEO}
                        </Moment>
                      )}
                    </td>
                    <td
                      className={`xe-ha`}
                      style={
                        showXeHa ? { borderBottom: "3px dashed #e6e6e6" } : null
                      }
                    >
                      {val.fieldData.SO_CONT}
                    </td>
                    <td
                      className={`xe-ha`}
                      style={
                        showXeHa ? { borderBottom: "3px dashed #e6e6e6" } : null
                      }
                    >
                      {val.fieldData.SO_SEAL}
                    </td>
                    <td
                      className={`xe-ha`}
                      style={
                        showXeHa ? { borderBottom: "3px dashed #e6e6e6" } : null
                      }
                    >
                      {val.fieldData.SO_MAX_GROSS}
                    </td>
                    <td
                      className={`xe-ha`}
                      style={
                        showXeHa ? { borderBottom: "3px dashed #e6e6e6" } : null
                      }
                    >
                      {val.fieldData.SO_TARE}
                    </td>
                    <td
                      className={`xe-ha text-center`}
                      style={
                        showXeHa ? { borderBottom: "3px dashed #e6e6e6" } : null
                      }
                    >
                      {val.fieldData.CONT_XONG_TEXT}
                    </td>
                    <td
                      className={`xe-ha text-center`}
                      style={
                        showXeHa ? { borderBottom: "3px dashed #e6e6e6" } : null
                      }
                    >
                      {val.fieldData.TK_XONG_TEXT}
                    </td>
                    <td
                      className={`xe-ha`}
                      style={
                        showXeHa ? { borderBottom: "3px dashed #e6e6e6" } : null
                      }
                    >
                      {val.fieldData.NOI_HA}

                      {showXeHa && (
                        <div style={{ position: "relative" }}>
                          <div
                            className="end-point"
                            style={{
                              position: "absolute",
                              width: "100%",
                              right: "-0.5rem",
                              margin: "-3px",
                              textAlign: "right",
                            }}
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="10"
                              height="3"
                            >
                              <rect width="10" height="3" fill="#1F9554"></rect>
                            </svg>
                          </div>
                        </div>
                      )}
                    </td>
                    <td
                      onClick={() =>
                        handleDataSiteShip(
                          val.fieldData.NOI_HA,
                          val.fieldData.TEN_TAU
                        )
                      }
                      style={{
                        cursor: "pointer",
                        color: `${
                          val.fieldData.CUT_OFF_COLOR === "Red" ? "#FF4C51" : ""
                        }`,
                        backgroundColor: `${
                          val.fieldData.CUT_OFF_COLOR === "Red"
                            ? "rgba(255, 76, 81, 0.3)"
                            : ""
                        }`,
                      }}
                    >
                      {val.fieldData.CUT_OFF && (
                        <Moment format="DD/MM/YYYY HH:mm">
                          {val.fieldData.CUT_OFF}
                        </Moment>
                      )}
                    </td>
                    <td className={"text-center"}>
                      {val.fieldData.zcPKLFile > 0 ? (
                        <button
                          className={"btn btn-sm p-0"}
                          onClick={() => showModal(val.recordId, "PKL")}
                        >
                          <img src={iconFile} alt={"FILE"} width={16} />
                        </button>
                      ) : (
                        ""
                      )}
                    </td>
                    <td className={"text-center"}>
                      {val.fieldData.zcVGMFile > 0 ? (
                        <button
                          className={"btn btn-sm p-0"}
                          onClick={() => PreviewImage(val.recordId, "VGM")}
                        >
                          <img src={iconImage} alt={"IMAGE"} width={16} />
                        </button>
                      ) : (
                        ""
                      )}
                    </td>
                    <td className={"text-center"}>
                      {val.fieldData.zcHinhHaHaHang > 0 ? (
                        <button
                          className={"btn btn-sm p-0"}
                          onClick={() => PreviewImage(val.recordId, "HHHH")}
                        >
                          <img src={iconImage} alt={"IMAGE"} width={16} />
                        </button>
                      ) : (
                        ""
                      )}
                    </td>
                    <td>
                      {val.fieldData.TG_HA_XONG && (
                        <Moment format="DD/MM/YYYY HH:mm">
                          {val.fieldData.TG_HA_XONG}
                        </Moment>
                      )}
                    </td>
                    <td>{val.fieldData.TEN_TAU}</td>
                    <td>{val.fieldData.SO_CHUYEN}</td>
                    <td>{val.fieldData.CHUYEN_TAI}</td>
                    <td>{val.fieldData.CANG_DEN}</td>
                  </tr>
                );
              })
            ) : (
              <tr>
                <td colSpan={25} className="text-center h5">
                  {dataError}
                </td>
              </tr>
            )}
          </tbody>
        </Table>
      </div>

      <div className="custom-row-paginate d-flex align-items-center">
        <div className={"col-12"}>
          <div className={"align-items-center"}>
            <div className={"hide-element text-black-50 pe-2"}>
              <Grid>
                <Grid item xs={12} sm={6}>
                  <div className="d-flex gap-0">
                    <CustomTextFieldStyle className="gap-0 mb-2">
                      Số dòng:
                      <FormControl variant="standard" sx={{ border: "none" }}>
                        <Select
                          variant="borderless"
                          defaultValue={limitRow}
                          onChange={(e) => {
                            handleChangeLimit(e);
                          }}
                          style={{
                            padding: 0,
                            width: "65px",
                            border: "none",
                          }}
                          marginInlineEnd={0}
                          optionPadding={0}
                          options={[
                            {
                              value: 10,
                              label: "10",
                            },
                            {
                              value: 20,
                              label: "20",
                            },
                            {
                              value: 50,
                              label: "50",
                            },
                            {
                              value: 100,
                              label: "100",
                            },
                          ]}
                        ></Select>
                      </FormControl>{" "}
                      <span className="ms-1">
                        {currentPage}-
                        {Math.min(currentPage - 1 + limit, pageNumbers)} /{" "}
                        {pageNumbers}
                      </span>
                    </CustomTextFieldStyle>
                    <CustomTextFieldStyle className="ms-2 mb-2">
                      {showPagination ? (
                        <Grid item xs={12} sm={6}>
                          <Pagination
                            count={Math.ceil(pageNumbers / limit)}
                            size="small"
                            forcePage={forcePage}
                            shape="rounded"
                            showFirstButton
                            showLastButton
                            color="primary"
                            defaultPage={1}
                            variant="text"
                            onChange={handleChangePage}
                            style={{ margin: "0 0 0 0", float: "right" }}
                          />
                        </Grid>
                      ) : null}
                    </CustomTextFieldStyle>
                    <span
                      className="text-black-50 footer-copyright float-end ms-auto mt-3 mb-2"
                      style={fStyle}
                    >
                      © {new Date().getFullYear()} Copyright by Grow Transport .
                      All rights reserved
                    </span>
                  </div>
                </Grid>
              </Grid>
            </div>
          </div>
        </div>
      </div>
      <Modal
        open={openMap}
        onOk={closeMap}
        onCancel={closeMap}
        footer={null}
        width={1200}
      >
        {latStart && longStart && latEnd && longEnd && (
          <MapContainer
            doubleClickZoom={false}
            scrollWheelZoom={true}
            id="mapId"
            zoom={15}
            center={[latStart, longStart]}
            style={{ width: "100%", height: "110vh" }}
          >
            <TileLayer
              url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
              attribution="Map data &copy; OpenStreetMap contributors"
            />

            <RoutineMachine
              latStart={latStart}
              longStart={longStart}
              latEnd={latEnd}
              longEnd={longEnd}
              color={color}
              markerPopup={markerPopup}
              markerPopupType={markerPopupType}
            />
          </MapContainer>
        )}
      </Modal>
      <ToastContainer />
    </div>
  );
};

export default DataTable;
