import React, { useEffect, useState } from "react";
import { Button, Input, Select } from "antd";
import { ModalBody, ModalHeader, Table, ModalTitle, ModalDialog } from "react-bootstrap";
import LoadingIcons from "react-loading-icons";
import { getSaiGonNewPort } from "../services/UserService";
import '../assets/scss/tableSiteShip.scss'

const SiteShip = (props) => {
  const [tenTau, setTenTau] = useState(null);
  const [dataTable, setDataTable] = useState(null);
  const [optionsData, setOptionsData] = useState([]);
  const [selectedValue, setSelectedValue] = useState(null);
  const optionSiteShip = localStorage.getItem('optionSiteShip');

  useEffect(() => {
    if (optionSiteShip) {
      const parseOption = JSON.parse(optionSiteShip);
      const dataOptions = parseOption.data.map(value => ({
        value: value.fieldData?.SiteID,
        label: value.fieldData?.KhuVuc
      }));
      setOptionsData(dataOptions);
      const filter = parseOption.data.filter(value => value.fieldData.NoiHa === props.dataSiteShip.noiHa);
      if (filter.length > 0) {
        setSelectedValue(filter[0].fieldData.SiteID);
      } else {
        setSelectedValue('GNL')
      }
      setTenTau(props.dataSiteShip.tenTau)
      getSaiGonNewPort(props.dataSiteShip.noiHa, props.dataSiteShip.tenTau).then(res => {
        const root = JSON.parse(res.data.response.scriptResult)
        setDataTable(root.model)
      });
    }

  }, [optionSiteShip, props.dataSiteShip.noiHa, props.dataSiteShip.tenTau]);

  const handleSelectChange = (value) => {
    console.log(value)
    setSelectedValue(value)
  };

  const handleTenTauChange = (e) => {
    setTenTau(e.target.value)
  };

  const handleSubmit = () => {

    getSaiGonNewPort(selectedValue, tenTau).then(res => {
      const root = JSON.parse(res.data.response.scriptResult)
      setDataTable(root.model)
    }).catch(e => {
      console.log(e)
    });
  }

  return (
    <ModalDialog className={'site-ship-page'}>
      <ModalHeader className={'pt-0 ps-0 mb-4'}>
        <h5>THÔNG TIN TÀU/CHUYẾN</h5>
      </ModalHeader>
      <ModalBody className={'m-0 p-0'}>
        <div className={'d-flex flex-row align-items-center gap-4'} style={{ fontWeight: 600 }}>
          <div>KHU VỰC<br />
            <Select className="mb-2"
              allowClear
              value={selectedValue}
              style={{ minWidth: 200 }}
              options={optionsData}
              onChange={handleSelectChange}
            />
          </div>
          <div>TÀU/CHUYẾN<br />
            <Input className="mb-2"
              value={tenTau}
              style={{ minWidth: 200 }}
              onChange={handleTenTauChange}
            />
          </div>
          <div><Button className="custom-btn custom-btn-primary me-3" size='sm'
            onClick={handleSubmit}>Tra cứu</Button>
          </div>
        </div>
        <div className="custom-table-sr">

          <Table bordered hover responsive="sm" className="custom-table text-center">
            <thead>
              <tr>
                <th>STT</th>
                <th>Cảng</th>
                <th>Đại lý Hãng Tàu</th>
                <th>Tên tàu</th>
                <th>Mã chuyến nhập/xuất</th>
                <th>Thời gian tàu cập thực tế</th>
                <th>Thời gian tàu rời thực tế</th>
                <th>Thời gian cảng ngừng tiếp nhận đăng ký xuất tàu</th>
                <th>Thời gian ICD ngừng tiếp nhận đăng ký xuất tàu</th>
                <th>Cổng</th>
                <th>Thời gian cảng tiếp nhận hàng xuất (container khô)</th>
                <th>Thời gian cảng tiếp nhận hàng xuất (container lạnh)</th>
                <th>Thời gian cảng tiếp nhận hàng xuất (container quá khổ)</th>
                <th>Thời gian cảng tiếp nhận hàng xuất (container hàng nguy hiểm)</th>
                <th>Ghi chú</th>
              </tr>
            </thead>
            <tbody>
              {dataTable ? (dataTable.map((val, key) => {
                return (
                  <tr key={key}>
                    <td>{key + 1}</td>
                    <td>{val.SITE_ID}</td>
                    <td>{val.AGENT}</td>
                    <td>{val.VESSELNAME}</td>
                    <td>{val.IN_OUT_VOYAGE}</td>
                    <td>{val.ACTUAL_BERTH_TIME}</td>
                    <td>{val.ACTUAL_DEPATURE_TIME}</td>
                    <td>{val.CLOSING_TIME}</td>
                    <td>{val.CLOSING_TIME_ICD}</td>
                    <td>{val.IN_GATE}</td>
                    <td>{val.OPEN_TS}</td>
                    <td>{val.REEFER_OPEN_TS}</td>
                    <td>{val.OOG_OPEN_TS}</td>
                    <td>{val.HAZ_OPEN_TS}</td>
                    <td className={'text-start'}>{val.REMARKS}</td>
                  </tr>
                )
              })) : (
                <tr>
                  <td colSpan={15} className="text-center">
                    <LoadingIcons.Puff stroke="#0059a9" />
                  </td>
                </tr>
              )}

            </tbody>
          </Table>
        </div>
      </ModalBody>
    </ModalDialog>
  );
};

export default SiteShip;
