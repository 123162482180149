import {Button, Card, Form, Row} from "react-bootstrap";
import {toast, ToastContainer} from "react-toastify";
import React, {useState} from "react";
import {changePassAPI} from "../../services/UserService";
import {useNavigate} from "react-router-dom";

function ChangePass() {
    const [password, setPassword] = useState('');
    const [rePassword, setRePassword] = useState('');
    const navigate = useNavigate();

    const handleCP = async () => {
        if (password !== rePassword) {
            toast.warning('Nhập mật khẩu không khớp, vui lòng thử lại!');
        } else if (!password && !rePassword) {
            toast.warning('Vui lòng nhập mật khẩu và thử lại!');
        } else {
            try {
                const userId = localStorage.getItem('userId');
                const token = localStorage.getItem('token');
                const data = JSON.stringify({
                    "fieldData": {
                        "zgEntryPassword": password,
                        "zgEntryPassword(2)": rePassword
                    },
                    "script": "CustomerUser - SaveNewPassword"
                });
                await changePassAPI(userId, token, data);
            } catch (error) {
                navigate('/login');
            }
        }
    };

    return (
        <div className={'cp-container'}>
            <div className="col-12">
                <div className="row mt-5">
                    <div className="col-lg-4 col-sm-6 col-xl-5 m-auto">
                        <Card className="border-0 p-4">
                            <Row className="mb-5">
                                <div className="row">
                                    <div className="m-auto text-center">
                                        <h5>Thay đổi mật khẩu</h5>
                                    </div>
                                </div>
                            </Row>
                            <Form>
                                <Form.Group className="mb-3" controlId="formBasicEmail">
                                    <Form.Label>Nhập mật khẩu</Form.Label>
                                    <Form.Control onChange={(e) => setPassword(e.target.value)}
                                                  type="password"/>
                                </Form.Group>

                                <Form.Group className="mb-3" controlId="formBasicPassword">
                                    <Form.Label>Nhập lại mật khẩu</Form.Label>
                                    <Form.Control onChange={(e) => setRePassword(e.target.value)}
                                                  type="password"/>
                                </Form.Group>

                                <Button variant="primary" onClick={handleCP} className="w-100 btn-submit">
                                    Xác nhận
                                </Button>
                            </Form>
                        </Card>
                    </div>
                </div>
                <ToastContainer/>
            </div>
        </div>
    )
}

export default ChangePass;