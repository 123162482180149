import { styled } from '@mui/material/styles'
// import MuiTabList from '@mui/lab/TabList'
import { Dialog } from '@mui/material'
// import TextField from '@mui/material/TextField'

const CustomTextFieldStyle = styled('div')(({ theme }) => ({
  display: 'flex',
  gap: 10,
  alignItems: 'center',
  paddingTop: 10,
  '& .MuiInputBase-input': {
    '&:not(textarea).MuiInputBase-inputSizeSmall': {
      padding: '5px 5px',
      minWidth: '3rem !important'
    }
  }
}))

const CustomFunctionDatePicker = styled('div')(({ theme }) => ({
  width: '100%'
}))

// const TabList = styled(MuiTabList)(({ theme }) => ({
//   borderBottom: '0 !important',
//   '& .MuiTabs-indicator': {
//     display: 'none',
//     borderBottom: 'none !important'
//   },
//   '& .Mui-selected': {
//     backgroundColor: theme.palette.primary.main,
//     color: `${theme.palette.common.white} !important`,
//     borderBottom: 'none !important'
//   },
//   '& .MuiTab-root': {
//     minHeight: 36,
//     minWidth: 110,
//     borderRadius: 6,
//     borderBottom: 'none !important'
//   }
// }))

const DialogStyle = styled(Dialog)(({ theme }) => ({
  '& .MuiDialog-container': {
    '& #dialog-up-count': {
      padding: '15px 20px 15px 20px',
      '& .MuiGrid-root': {
        gap: '20px',
        alignItems: 'center'
      },
      '& .MuiBox-root': {
        flexWrap: 'nowrap',
        alignItems: 'flex-start',
        '& h3': {
          margin: '0'
        },
        '& .dialog-actions-dense': {
          padding: '0'
        }
      }
    }
  }
}))

export {CustomTextFieldStyle, CustomFunctionDatePicker, DialogStyle }
